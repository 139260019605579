import axiosIns from '@/libs/axios'
import rotasAPI from '@/router/rotasAPI'


// Dashboard Admin Empresa - Filter Fields
export const getDashboardFilterOptions = (params) => new Promise((resolve, reject) => {
    axiosIns
        .get(rotasAPI.getDashboardFilterOptionsAdminEmpresa(), { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
})

// Dashboard Admin Empresa - Dashboard Data
export const getDashboardData = (empresasVinculadasUsuario, campanhas, uf) => new Promise((resolve, reject) => {
    const headers = {
        'campanha': campanhas,
        'empresas': empresasVinculadasUsuario
    }

    axiosIns
        .get(rotasAPI.getDashboardDataAdminEmpresa(), { headers, params: { uf } })
        .then(response => resolve(response))
        .catch(error => reject(error))
})