<template>
    <div class="dashboard-comparative-data panel">
        <div class="padding-up">
            <div class="align-button">
                <button @click="downloadExcel()" class="button-spreadsheet"><span><img class="start-icon"
                            src="@/assets/custom-icons/pages/dashboard/start-icon.svg" alt=""></span> Baixar planilha</button>
            </div>
        </div>
        <table class="table-container">
            <thead>
                <tr>
                    <th>
                        <span class="span-tr">EMPRESA | CNPJ
                            <div class="align-external-imgs-donw-up">
                                <div class="align-imgs-down-up">
                                    <svg @click="sortByAsc('nome')"
                                        :class="{ 'img-asc': orderByColumn === 'nome' && isAscending, 'img-desc': orderByColumn === 'nome' && !isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291244" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291244)">
                                            <rect x="0.915894" width="14.0841" height="14" :fill="colors.nome.asc" />
                                        </g>
                                    </svg>
                                    <svg @click="sortByDesc('nome')"
                                        :class="{ 'img-asc': orderByColumn === 'nome' && !isAscending, 'img-desc': orderByColumn === 'nome' && isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291243" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291243)">
                                            <rect x="0.915894" width="14.0841" height="14" :fill="colors.nome.desc" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </span>
                    </th>

                    <th>
                        <span class="span-tr">Doses<br>Solicitadas
                            <div class="align-external-imgs-donw-up">
                                <div class="align-imgs-down-up">
                                    <svg @click="sortByAsc('doses_solicitadas')"
                                        :class="{ 'img-asc': orderByColumn === 'doses_solicitadas' && isAscending, 'img-desc': orderByColumn === 'doses_solicitadas' && isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291244" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291244)">
                                            <rect x="0.915894" width="14.0841" height="14"
                                                :fill="colors.doses_solicitadas.asc" />
                                        </g>
                                    </svg>
                                    <svg @click="sortByDesc('doses_solicitadas')"
                                        :class="{ 'img-asc': orderByColumn === 'doses_solicitadas' && !isAscending, 'img-desc': orderByColumn === 'doses_solicitadas' && !isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291243" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291243)">
                                            <rect x="0.915894" width="14.0841" height="14"
                                                :fill="this.colors.doses_solicitadas.desc" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </span>
                    </th>
                    <th>
                        <span class="span-tr">Doses<br>Restantes
                            <div class="align-external-imgs-donw-up">
                                <div class="align-imgs-down-up">

                                    <svg @click="sortByAsc('doses_restantes')"
                                        :class="{ 'img-asc': orderByColumn === 'doses_restantes' && isAscending, 'img-desc': orderByColumn === 'doses_restantes' && isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291244" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291244)">
                                            <rect x="0.915894" width="14.0841" height="14"
                                                :fill="colors.doses_restantes.asc" />
                                        </g>
                                    </svg>
                                    <svg @click="sortByDesc('doses_restantes')"
                                        :class="{ 'img-asc': orderByColumn === 'doses_restantes' && !isAscending, 'img-desc': orderByColumn === 'doses_restantes' && !isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291243" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291243)">
                                            <rect x="0.915894" width="14.0841" height="14"
                                                :fill="colors.doses_restantes.desc" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </span>
                    </th>
                    <th>
                        <span class="span-tr">Pessoas<br>Vacinadas
                            <div class="align-external-imgs-donw-up">
                                <div class="align-imgs-down-up">
                                    <svg @click="sortByAsc('pessoas_vacinadas')"
                                        :class="{ 'img-asc': orderByColumn === 'pessoas_vacinadas' && isAscending, 'img-desc': orderByColumn === 'pessoas_vacinadas' && isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291244" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291244)">
                                            <rect x="0.915894" width="14.0841" height="14"
                                                :fill="colors.pessoas_vacinadas.asc" />
                                        </g>
                                    </svg>
                                    <svg @click="sortByDesc('pessoas_vacinadas')"
                                        :class="{ 'img-asc': orderByColumn === 'pessoas_vacinadas' && !isAscending, 'img-desc': orderByColumn === 'pessoas_vacinadas' && !isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291243" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291243)">
                                            <rect x="0.915894" width="14.0841" height="14"
                                                :fill="colors.pessoas_vacinadas.desc" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </span>
                    </th>
                    <th>
                        <span class="span-tr">Andamento<br>Vacinação
                            <div class="align-external-imgs-donw-up">
                                <div class="align-imgs-down-up">
                                    <svg @click="sortByAsc('andamento_vacinacao')"
                                        :class="{ 'img-asc': orderByColumn === 'andamento_vacinacao' && isAscending, 'img-desc': orderByColumn === 'andamento_vacinacao' && isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291244" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291244)">
                                            <rect x="0.915894" width="14.0841" height="14"
                                                :fill="colors.andamento_vacinacao.asc" />
                                        </g>
                                    </svg>

                                    <svg @click="sortByDesc('andamento_vacinacao')"
                                        :class="{ 'img-asc': orderByColumn === 'andamento_vacinacao' && !isAscending, 'img-desc': orderByColumn === 'andamento_vacinacao' && !isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291243" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291243)">
                                            <rect x="0.915894" width="14.0841" height="14"
                                                :fill="colors.andamento_vacinacao.desc" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </span>
                    </th>
                    <th>
                        <span class="span-tr">Pessoas<br>Cadastradas
                            <div class="align-external-imgs-donw-up">
                                <div class="align-imgs-down-up">
                                    <svg @click="sortByAsc('pessoas_cadastradas')"
                                        :class="{ 'img-asc': orderByColumn === 'pessoas_cadastradas' && isAscending, 'img-desc': orderByColumn === 'pessoas_cadastradas' && isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291244" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291244)">
                                            <rect x="0.915894" width="14.0841" height="14"
                                                :fill="colors.pessoas_cadastradas.asc" />
                                        </g>
                                    </svg>
                                    <svg @click="sortByDesc('pessoas_cadastradas')"
                                        :class="{ 'img-asc': orderByColumn === 'pessoas_cadastradas' && !isAscending, 'img-desc': orderByColumn === 'pessoas_cadastradas' && !isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291243" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291243)">
                                            <rect x="0.915894" width="14.0841" height="14"
                                                :fill="colors.pessoas_cadastradas.desc" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </span>
                    </th>
                    <th>
                        <span class="span-tr">Pessoas Não<br>Vacinadas
                            <div class="align-external-imgs-donw-up">
                                <div class="align-imgs-down-up">
                                    <svg @click="sortByAsc('pessoas_nao_vacinadas')"
                                        :class="{ 'img-asc': orderByColumn === 'pessoas_nao_vacinadas' && isAscending, 'img-desc': orderByColumn === 'pessoas_nao_vacinadas' && isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291244" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291244)">
                                            <rect x="0.915894" width="14.0841" height="14"
                                                :fill="colors.pessoas_nao_vacinadas.asc" />
                                        </g>
                                    </svg>
                                    <svg @click="sortByDesc('pessoas_nao_vacinadas')"
                                        :class="{ 'img-asc': orderByColumn === 'pessoas_nao_vacinadas' && !isAscending, 'img-desc': orderByColumn === 'pessoas_nao_vacinadas' && !isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291243" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291243)">
                                            <rect x="0.915894" width="14.0841" height="14"
                                                :fill="colors.pessoas_nao_vacinadas.desc" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </span>
                    </th>
                    <th>
                        <span class="span-tr">Vacinados<br>/Total
                            <div class="align-external-imgs-donw-up">
                                <div class="align-imgs-down-up">

                                    <svg @click="sortByAsc('vacinados_total')"
                                        :class="{ 'img-asc': orderByColumn === 'vacinados_total' && isAscending, 'img-desc': orderByColumn === 'vacinados_total' && isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291244" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291244)">
                                            <rect x="0.915894" width="14.0841" height="14"
                                                :fill="colors.vacinados_total.asc" />
                                        </g>
                                    </svg>

                                    <svg @click="sortByDesc('vacinados_total')"
                                        :class="{ 'img-asc': orderByColumn === 'vacinados_total' && !isAscending, 'img-desc': orderByColumn === 'vacinados_total' && !isAscending }"
                                        width="15" height="14" viewBox="0 0 15 14" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                            fill="black" />
                                        <mask id="mask0_16963_291243" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                            x="3" y="4" width="10" height="6">
                                            <path
                                                d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                                fill="white" />
                                        </mask>
                                        <g mask="url(#mask0_16963_291243)">
                                            <rect x="0.915894" width="14.0841" height="14"
                                                :fill="colors.vacinados_total.desc" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(empresasComparativo, index) in empresasComparativo" :key="index">
                    <td class="max-td">{{ empresasComparativo.nome }}<div class="cpnj">{{ empresasComparativo.cnpj }}</div>
                    </td>
                    <td>{{ empresasComparativo.doses_solicitadas }}</td>
                    <td>{{ empresasComparativo.doses_restantes }}</td>
                    <td>{{ empresasComparativo.pessoas_vacinadas }}</td>
                    <td>{{ parseFloat(empresasComparativo.andamento_vacinacao).toFixed(0) }}%</td>
                    <td>{{ empresasComparativo.pessoas_cadastradas }}</td>
                    <td>{{ empresasComparativo.pessoas_nao_vacinadas }}</td>
                    <td>{{ parseFloat(empresasComparativo.vacinados_total).toFixed(0) }}%</td>
                </tr>
            </tbody>
        </table>
        <p class="arrow-container"><span class="arrow up-arrow"></span></p>
        <div class="arrow-up"></div>
    </div>
</template>
<script>
import * as XLSX from 'xlsx';
export default {

    props: {
        empresasComparativo: {
            required: true
        }
    },
    components: {
    },

    created() {
        this.fields = [{ nome: false, }, { doses_solicitadas: false }, { doses_restantes: false },
        { pessoas_vacinadas: false }, { andamento_vacinacao: false }, { pessoas_cadastradas: false }, { pessoas_nao_vacinadas: false },
        { vacinados_total: false }
        ];
    },

    data() {
        return {
            orderByColumn: "",
            isAscending: true,
            imgAscClass: '',
            imgDescClass: '',
            boolean: true,
            colors: {
                nome: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                doses_solicitadas: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                doses_restantes: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                pessoas_vacinadas: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                andamento_vacinacao: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                pessoas_cadastradas: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                pessoas_nao_vacinadas: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                vacinados_total: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
            }
        };
    },

    methods: {

        downloadExcel() {
            // Obtém a data atual
            const dataAtual = new Date();
            // Formata a data atual para o estilo PT/BR
            const dataFormatada = dataAtual.toLocaleString('pt-BR', {
                timeZone: 'America/Sao_Paulo', // Define o fuso horário para Brasília
                dateStyle: 'short', // Especifica o formato da data
                timeStyle: 'medium' // Especifica o formato da hora com minutos e segundos
            }).replace(/[/]/g, '-').replace(/[,]/g, '').replace(/[ ]/g, ', ');

            // Formata a data atual para o estilo PT/BR
            const dataFormatadaTitle = dataAtual.toLocaleDateString('pt-BR', {
                timeZone: 'America/Sao_Paulo', // Define o fuso horário para Brasília
                dateStyle: 'short' // Especifica o formato da data
            }).replace(/[/]/g, '-');

            // Formata a hora atual para o estilo PT/BR
            const horaFormatadaTitle = dataAtual.toLocaleTimeString('pt-BR', {
                timeZone: 'America/Sao_Paulo', // Define o fuso horário para Brasília
                hour12: false // Use o formato de 24 horas
            }).replace(/[:]/g, '-');

            // Extrai os dados da tabela
            const data = this.empresasComparativo.map(empresasComparativo => [
                empresasComparativo.nome,
                empresasComparativo.cnpj,
                empresasComparativo.doses_solicitadas,
                empresasComparativo.doses_restantes,
                empresasComparativo.pessoas_vacinadas,
                parseFloat(empresasComparativo.andamento_vacinacao).toFixed(0) + "%",
                empresasComparativo.pessoas_cadastradas,
                empresasComparativo.pessoas_nao_vacinadas,
                parseFloat(empresasComparativo.vacinados_total).toFixed(0) + "%",
                dataFormatada // Adiciona a data e hora formatadas
            ]);

            // Cria uma nova planilha
            const ws = XLSX.utils.aoa_to_sheet([['Empresa', 'CNPJ', 'Doses Solicitadas', 'Doses Restantes', 'Pessoas Vacinadas', 'Andamento Vacinação', 'Pessoas Cadastradas', 'Pessoas Não Vacinadas', 'Vacinados Total', 'Data e Hora'], ...data]);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Empresas');

            // Ajustar a largura das colunas
            const columnWidths = [
                { wch: 20 }, // Largura padrão para a primeira coluna 'Empresa'
                { wch: 15 }, // Largura padrão para a segunda coluna 'CNPJ'
                { wch: 15 }, // Largura padrão para as outras colunas
                { wch: 15 },
                { wch: 15 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 25 }
            ];

            // Iterar sobre as colunas para ajustar a largura
            for (let i = 0; i < columnWidths.length; i++) {
                ws['!cols'] = columnWidths;
            }

            // Formata o nome do arquivo com a data e hora atual
            const nomeArquivo = `comparativos-empresas-data-${dataFormatadaTitle}-hora-${horaFormatadaTitle}.xlsx`;
            // Escreve o arquivo XLSX
            XLSX.writeFile(wb, nomeArquivo);
        },

        sortByAsc(column) {
            if (this.orderByColumn === column && this.isAscending) {
                this.isAscending = false; // Alterna a direção da ordenação
            } else {
                this.isAscending = true;
                this.orderByColumn = column;
            }
            this.updateColors();
            this.sortBy(column);

        },
        sortByDesc(column) {
            if (this.orderByColumn === column && !this.isAscending) {
                this.isAscending = true; // Alterna a direção da ordenação
            } else {
                this.isAscending = false;
                this.orderByColumn = column;
            }
            this.updateColors();
            this.sortBy(column);

        },

        updateColors(column, ascending) {
            for (const key in this.colors) {
                if (key === this.orderByColumn) {
                    this.colors[key].asc = this.isAscending ? '#6E6B7B' : '#BABFC7'; // Altera a cor da seta ascendente
                    this.colors[key].desc = this.isAscending ? '#BABFC7' : '#6E6B7B'; // Altera a cor da seta descendente
                } else {
                    this.colors[key].asc = '#BABFC7'; // Cor padrão para outras colunas
                    this.colors[key].desc = '#BABFC7'; // Cor padrão para outras colunas
                }
            }
        },
        sortBy(column) {
            this.orderByColumn = column;
            const fieldIndex = this.fields.findIndex(field => Object.keys(field)[0] === column);
            this.fields.forEach(field => {
                const fieldName = Object.keys(field)[0];
                field[fieldName] = false;
            });

            if (fieldIndex !== -1) {
                this.fields[fieldIndex][column] = true;
                this.sortData();
            }

        },
        sortData() {
            this.empresasComparativo.sort((a, b) => {
                const valueA = this.getValueForColumn(a, this.orderByColumn);
                const valueB = this.getValueForColumn(b, this.orderByColumn);

                let comparison;
                if (typeof valueA === 'string') {
                    comparison = this.isAscending
                        ? valueA.localeCompare(valueB, 'en', { numeric: true })
                        : valueB.localeCompare(valueA, 'en', { numeric: true });
                } else {
                    comparison = !this.isAscending
                        ? valueA - valueB
                        : valueB - valueA;
                }

                return comparison;
            });
        },
        getValueForColumn(empresasComparativo, column) {
            switch (column) {
                case 'nome':
                    return empresasComparativo.nome.toLowerCase();
                case 'cnpj':
                    return empresasComparativo.cnpj;
                case 'doses_solicitadas':
                    return parseFloat(empresasComparativo[column]);
                case 'doses_restantes':
                    return parseFloat(empresasComparativo[column]);
                case 'pessoas_vacinadas':
                    return parseFloat(empresasComparativo[column]);
                case 'andamento_vacinacao':
                    return parseFloat(empresasComparativo[column]);
                case 'pessoas_cadastradas':
                    return parseFloat(empresasComparativo[column]);
                case 'pessoas_nao_vacinadas':
                    return parseFloat(empresasComparativo[column]);
                case 'vacinados_total':
                    return parseFloat(empresasComparativo[column]);
                default:
                    return '';
            }
        },
    },
}
</script>
  
  
<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";

/* CSS RESET */
* {
    margin: 0;
    padding: 0;
    border: 0;
}

.max-td {
    min-width: 30% !important;
}

.align-external-imgs-donw-up {
    width: 100%;
    display: flex;
    justify-content: start;
    justify-items: center;
    align-items: center;
    align-content: center;
    padding-left: responsive-width(10px);
}

.img-asc {
    cursor: pointer;
}


.img-desc {
    cursor: pointer;
}

.align-button {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: responsive-width(22px);
}

.button-spreadsheet {
    width: responsive-width(177px);
    height: responsive-height(37px);
    border-radius: 5px;
    background-color: #2772C0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat, sans-serif;
    font-size: responsive-height(14px);
    font-weight: 500;
    line-height: responsive-height(17px);
    letter-spacing: responsive-height(0.4000000059604645px);
    color: #FFFFFF;
    &:hover {
            background-color: #1a508a;
        }
}

.padding-up {
    height: responsive-height(77px);
    display: flex;
    align-items: center;
}

.align-imgs-down-up {
    display: flex;
    flex-direction: column;
}

.table-container tr {
    font-size: mobile-responsive-height(12px) !important;
    padding-top: responsive-height(20px);
    border-bottom: 1px solid #ddd;
}

.table-container {
    width: 100%;
}

.table-container svg {
    cursor: pointer;
}

.cnpj {
    display: block;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
}

.span-tr {
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.table-container tr th {
    font-family: Montserrat, sans-serif;
    font-size: responsive-height(12px);
    font-weight: 600;
    line-height: responsive-height(15px);
    letter-spacing: 0px;
    text-align: left;
    height: responsive-height(40px);
    color: #5E5873;
    padding-left: responsive-width(16px);
    background-color: #EFEFEF !important;

}

.table-container td {
    padding-left: responsive-width(16px);
    padding-top: responsive-height(14px);
    padding-bottom: responsive-height(14px);
    font-family: Montserrat, sans-serif;
    font-size: responsive-height(14px);
    font-weight: 600;
    line-height: responsive-height(21px);
    letter-spacing: 0px;
    text-align: left;

}

.table-container td div {
    font-family: Montserrat, sans-serif;
    font-size: responsive-height(12px);
    font-weight: 400;
    line-height: responsive-height(15px);
    letter-spacing: 0px;
    text-align: left;
    color: #6E6B7B;
}

.table-container tr:first-child {
    border-top: none;
}

.dashboard-comparative-data {
    padding: 0;
    min-height: 450px;
}

.start-icon {
    width: responsive-width(14px);
    height: responsive-width(14px);
    margin-right: responsive-width(8px);
}
</style>
  