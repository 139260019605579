<template>
    <div class="dashboard-filter panel">
        <div class="data-filter">
            <div class="filter-selection">
                
                <SingleSelectFilter 
                    :field = "'Grupo'"
                    :optionsDropdown = "groupDropdown"
                    :deselectable = true
                    v-model = "selectedGroup"
                    v-if="nacional"
                    @input="paramsUpdate"
                    />
                <SingleSelectFilter 
                    :field = "'DR SESI'"
                    :optionsDropdown = "departmentDropdown"
                    :deselectable = true
                    :tooltip="'Filtre para visualizar os dados de acordo com o atendimento realizado pelo SESI'"
                    v-model = "selectedDepartment"
                    v-if="nacional"
                    @input="paramsUpdate"
                    />

                <SingleSelectFilter 
                    :field = "'UF'"
                    :optionsDropdown = "statesDropdown"
                    :deselectable = true
                    :tooltip="nacional ? 'Filtre para visualizar os dados de acordo com a distribuição estadual das suas empresas' : ''"
                    v-model = "selectedState"
                    v-if="nacional"
                    @input="paramsUpdate"
                    />

                    <SingleSelectFilter 
                    :field = "'Estado'"
                    :optionsDropdown = "statesDropdown"
                    :deselectable = true
                    v-model = "selectedState"
                    v-if="!nacional"
                    @input="paramsUpdate"
                    />

                <MultipleSelectFilter 
                    :field = "'Empresa'"
                    :optionsDropdown = "companiesDropdown" 
                    v-model = "selectedCompanies"
                    @input="paramsUpdate"
                    />

                <SingleSelectFilter 
                    :field = "'Campanha'"
                    :optionsDropdown = "campaignsDropdown"
                    v-model = "selectedCampaign"
                    @input="resetFilters"
                    />
            </div>
            <div class="filter-buttons">
                <button class="filter-btn" @click="filterData()">Filtrar</button>
                <button class="reset-btn" @click="resetFilters()">Limpar</button>
            </div>
        </div>
        <FilterTags :allTags = "activeFilterTags" />
    </div>
</template>

<script>
import dashUtilsMixin from "../../shared-mixins/dashUtilsMixin"
import SingleSelectFilter from "@/views/custom-pages/dashboard/shared-components/SingleSelectFilter.vue"
import MultipleSelectFilter from "@/views/custom-pages/dashboard/shared-components/MultipleSelectFilter.vue"
import FilterTags from "@/views/custom-pages/dashboard/shared-components/FilterTags.vue"

export default {
    components: {
        SingleSelectFilter,
        MultipleSelectFilter,
        FilterTags
    },

    mixins: [ dashUtilsMixin ],

    props: {
        filterFields: {
            type: Object,
            required: true
        },
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            selectedState: null,
            selectedGroup: null,
            selectedCompanies: [],
            selectedDepartment: null,
            selectedCampaign: this.value,
            activeFilterTags: [],
            all: {
                states: [],
                groups: [],
                departments: [],
                companies: []
            }
        }
    },

    computed: {
        campaignChange() {
            return this.value.id != this.selectedCampaign.id
        },
        nacional() {
            return this.selectedCampaign.nacional
        },
        statesDropdown() {
            let statesSelect = []

            if(!this.selectedGroup && !this.selectedDepartment && !this.campaignChange){
                statesSelect = this.all.states
            }
            else if(this.selectedDepartment && this.selectedState && !this.campaignChange){
                statesSelect = this.all.states.filter(state => this.selectedDepartment.ufInDepartment.includes(state.id))
            }
            else{
                statesSelect = this.filterFields.allStates
            }

            if(this.selectedCompanies.length){
                statesSelect = this.filterFields.allStates
            }

            this.orderAlphabetically(statesSelect, true);
            return statesSelect
        },
        groupDropdown() {
            let groupSelect = []

            if(!this.campaignChange){
                groupSelect = this.all.groups
            }else{
                groupSelect = this.filterFields.allGroups
            }

            this.orderAlphabetically(groupSelect)
            return groupSelect
        },
        departmentDropdown() {
            let operatorSelect = []

            if(!this.selectedGroup && !this.campaignChange){
                operatorSelect = this.all.departments
            }
            else if(this.selectedGroup && this.selectedDepartment && !this.campaignChange){
                operatorSelect = this.all.departments.filter(operator => this.selectedGroup.drInGroup.includes(operator.id))
            }
            else{
                operatorSelect = this.filterFields.allOperators
            }

            this.orderAlphabetically(operatorSelect)
            return operatorSelect
        },
        companiesDropdown() {
            let companiesList = []

            if(!this.selectedGroup && !this.selectedDepartment && !this.selectedState && !this.campaignChange){
                companiesList = this.all.companies
            }else{
                let companies = this.selectedCompanies.filter(company => this.filterFields.allCompanies.find(c => c.id == company.id))
                
                if(companies.length != this.selectedCompanies.length){
                    this.selectedCompanies = companies
                    this.paramsUpdate()
                }
                companiesList = this.filterFields.allCompanies
            }

            this.orderAlphabetically(companiesList)
            return companiesList
        },
        campaignsDropdown() {
            let campaignsList = this.filterFields.allCampaigns

            this.orderAlphabetically(campaignsList)
            this.orderByRecentDate(campaignsList)
            return campaignsList
        },
        companiesToFilter() {
            let companiesToFilter = []

            // If 0 companies were selected, the entire list is filtered. Otherwise, only the selected companies are filtered.
            if (this.selectedCompanies.length == 0) companiesToFilter = [...this.companiesDropdown]
            else companiesToFilter = [...this.selectedCompanies]
            
            companiesToFilter.sort((a, b) => a.id - b.id)
            return companiesToFilter
        }
    },
    mounted() {
        this.setDropdowns()
        this.generateActiveFilterTags()
    },
    methods: { 
        setDropdowns() {
            this.all.states = this.filterFields.allStates
            this.all.groups = this.filterFields.allGroups
            this.all.departments = this.filterFields.allOperators
            this.all.companies = this.filterFields.allCompanies
        },
        filterData() {
            const fieldsToFilter = {
                companies: this.companiesToFilter.map(company => company.id),
                campaign: [ this.selectedCampaign.id ],
                uf: this.selectedState ? this.selectedState.id : null,
            }
            
            this.$emit("filterUpdade", fieldsToFilter)
        },
        checkParams() {
            if(this.selectedGroup){
                this.selectedDepartment = this.selectedDepartment && this.selectedGroup.drInGroup.includes(this.selectedDepartment.id) ? this.selectedDepartment : null
                this.selectedState = this.selectedState && this.selectedGroup.ufInGroup.includes(this.selectedState.id) ? this.selectedState : null
            }

            if(this.selectedDepartment){
                this.selectedState = this.selectedState && this.selectedDepartment.ufInDepartment.includes(this.selectedState.id) ? this.selectedState : null
            }

            if(this.selectedState){
                this.selectedCompanies = this.selectedCompanies.filter(company => this.selectedState.companiesInState.includes(company.id))
            }
        },
        paramsUpdate() {
            if(this.campaignChange){
                this.resetFilters()
                return;
            }

            this.checkParams();

            this.paramsUpdateFunction()
        },
        paramsUpdateFunction(){
            this.$emit('paramsUpdate', {
                id_campanha: this.selectedCampaign.id,
                id_grupo: this.selectedGroup ? this.selectedGroup.id : null,
                uf_operador: this.selectedDepartment ? this.selectedDepartment.id : null,
                uf_empresa: this.selectedState ? this.selectedState.id : null,
                ids_empresa: this.selectedCompanies.map(company => company.id)
            })
        },
        resetFilters() {
            this.selectedState = null
            this.selectedGroup = null
            this.selectedDepartment = null
            this.selectedCompanies = []
            this.$emit('paramsUpdate', {
                id_campanha: this.selectedCampaign.id
            })
        },
        addFilterTag(selectedItem, defaultText) {
        const text = selectedItem ? selectedItem.name : defaultText;
        this.activeFilterTags.push({ text: text, title: text });
        },

        generateActiveFilterTags() {
            this.activeFilterTags = []
            let tagText = ""
            let tagTitle = ""

            if (this.nacional) {
            this.addFilterTag(this.selectedGroup, "Todos os Grupos");
            this.addFilterTag(this.selectedDepartment, "Todas os DRs");
            }

            this.addFilterTag(this.selectedState, this.nacional ? "Todas as UFs" : "Todos os Estados");
            
            let companiesTags = [...this.companiesToFilter]
            this.orderAlphabetically(companiesTags)

            // Less than 10 Companies = 1 tag for each.
            if (companiesTags.length <= 10) {
                for (const company of companiesTags) {
                    tagText = tagTitle = company.name
                    this.activeFilterTags.push({ text: tagText, title: tagTitle })
                }
            
            // More than 10 Companies = 1 tag for All Companies.
            } else {
                tagText = `${companiesTags.length} Empresas`
                tagTitle = ""

                for (const company of companiesTags) {
                    tagTitle += `${company.name}\n`
                }

                this.activeFilterTags.push({ text: tagText, title: tagTitle })
            }

            tagText = tagTitle = `${this.selectedCampaign.name} - Início em ${this.displayDateFormat(this.selectedCampaign.startDate)}`
            this.activeFilterTags.push({ text: tagText, title: tagTitle })
        }
    },
}
</script>