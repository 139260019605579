import moment from 'moment';
import gsap from 'gsap';

export default {
    filters: {
		addDots(number) {
			return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
		}
	},
    
    methods: {
        getMostRecent(array) {
            let mostRecent = array[0]

            for (let i = 1; i < array.length; i++) {
                const date = new Date(mostRecent.startDate)
                const nextDate = new Date(array[i].startDate)

                if (nextDate > date) mostRecent = array[i]
            }

            return mostRecent
        },

        standardDateFormat(displayDate) {
            const dateParts = displayDate.split('/')
            const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
            return moment(date).format('YYYY-MM-DD')
        },

        displayDateFormat(dateString) {
            if (dateString.length == 10) {
                const dateParts = dateString.split('-')
                const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
                return moment(date).format('DD/MM/YYYY')
            } else {
                return moment(dateString).format('DD/MM/YYYY')
            }
        },

        startGreaterThanEndDate(startDate, endDate) {
            return this.standardDateFormat(startDate) > this.standardDateFormat(endDate)
        },

        validateDate(date) {
            var regex = /^\d{2}\/\d{2}\/\d{4}$/          // Date Format: DD/MM/YYYY
            if (!regex.test(date)) return false

            const fullDate = date.split('/')
            var day = parseInt(fullDate[0], 10)
            var month = parseInt(fullDate[1], 10)
            var year = parseInt(fullDate[2], 10)
            
            var daysInMonth = new Date(year, month, 0).getDate()

            if (month < 1 || month > 12) return false
            if (day < 1 || day > daysInMonth) return false
            if (year < 1000) return false

            const validDate = this.standardDateFormat(date)
            if (validDate < '2022-01-01' || validDate > moment().format('YYYY-MM-DD')) return false
            
            return true
        },

        orderByRecentDate(array) {
            array.sort((a, b) => {
                let date = new Date(a.startDate);
                let nextDate = new Date(b.startDate);
                return nextDate - date;
            })
        },

        orderAlphabetically(array, ufs = false) {
            const fieldSort = ufs ? 'id' : 'name'; 

            array.sort((a, b) => {
                if (a[fieldSort] < b[fieldSort]) return -1
                else if (a[fieldSort] > b[fieldSort]) return 1
                return 0
            })
        },

        animateNumber(property, newValue) {
            gsap.to(this.$data, {
                duration: 1,
                [property]: newValue,
                onUpdate: () => {
                    this[property] = this.$data[property]
                }
            })
        }
    }
}