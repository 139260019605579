<template>
    <transition-group name="tag" class="active-filters-display" mode="out-in">
        <span :key="'Filtros Ativos'" v-if="allTags.length > 0">Filtros Ativos: </span>
        <div class="filter-tag tag-move" v-for="tag of allTags" :title="tag.title" :key="tag.text">{{ tag.text }}</div>
    </transition-group>
</template>

<script>
export default {
    props: {
        allTags: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_responsivity.scss";

.active-filters-display {
    display: flex;
    flex-wrap: wrap;
    gap: responsive-width(12px);
    font-size: responsive-height(14px);
    line-height: responsive-height(26px);
    
    .filter-tag {
        border-radius: 4px;
        background: #2772C0;
        color: #fff;
        font-size: responsive-height(12px);
        padding: 0px responsive-width(12px);
        max-width: responsive-width(200px);
        overflow: hidden; 
        text-overflow: ellipsis;
        white-space: nowrap; 
    }
}

/* Tags Animation */
.tag-enter, .tag-leave-to {
    transform: scale(0.1); 
    opacity: 0; 
}

.tag-move, .tag-enter-active {
    transition: transform .3s, opacity .2s;
}

.tag-leave-active {
    position: absolute;
    transition: transform .4s, opacity .3s;
}

// MOBILE
@media only screen and (max-width: 780px) {
    .active-filters-display .filter-tag {
        max-width: 48%;

        &:last-child {
            max-width: 100%;
        }
    }
}
</style>